<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="advantages" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="main.intro.header"
                  :messages="['The recommended number of characters is 15']"
                />
                <div style="margin-bottom: 15px;">
                  <span>Description</span>
                  <ckeditor v-model="main.intro.desc" :editor="editor" :config="editorConfig"></ckeditor>
                </div>
                <va-card class="mb-3" title="Image *">
                  <div v-if="imgIntroExisting">
                    <a style="display: block;" target="_blank" :href="imgIntroExisting"><img style="max-width: 400px;" :src="imgIntroExisting"></a>
                    <div><va-button small color="danger" @click="imgIntroExisting = deleteImage(imgId) ? '' : imgIntroExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    @input="delete errors.img"
                    type="gallery"
                    dropzone
                    v-model="img"
                    style="margin-bottom: 25px;"
                  />
                  <div v-if="errors.img" style="color: red;">{{ this.errors.img[0] }}</div>
                  <va-button @click="uploadImage(img[0])">Завантажити</va-button>
                </va-card>

              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-input
                  label="Header"
                  v-model="main.highlightContent.header"
                  :messages="['The recommended number of characters is 15']"
                />

              </div>
              <div class="flex xs12" v-if="tabValue === 2">
                <va-data-table
                  :fields="tabFields"
                  :data="items"
                  no-pagination
                >

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'bgk-page-items', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    ckeditor: CKEditor.component,
  },
  data () {
    return {
      main: {
        intro: {
          desc: null,
          header: null,
          img: null,
        },
        desc: {
          postheader: null,
          header: null,
          desc: null,
        },
      },
      items: null,
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      imgIntroExisting: null,
      imageNew: null,
      img: [],

      errors: [],

      tabTitles: ['Main', 'Principles', 'Items'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    togglePublished (id) {
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/items/${id}`, {
        published: !!this.items.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    uploadImage (file) {
      console.log(file)
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('file', file)
      axios.post(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/media`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.imageNew = response.data.patch
          this.imgId = response.data.id
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage () {
      this.imgIntroExisting = ''
      this.imageNew = null
    },
    submit () {
      this.main.intro.image = this.imageNew
      const data = {
        main: this.main,
      }
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/advantages`, data)
        .then(response => {
          this.$router.push({ name: 'bgk-page-advantages' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/advantages`)
        .then(response => {
          console.log(response.data)
          this.main = response.data.main
          this.items = response.data.items
          this.imgIntroExisting = process.env.VUE_APP_BGK_URL + response.data.main.intro.image
          this.imageNew = response.data.main.intro.image
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    tabFields () {
      return [{
        name: 'name',
        title: 'Name',
        width: '40%',
      },
      {
        title: 'Опубліковано',
        name: '__slot:toggle',
        width: '20%',
      },
      {
        title: 'Переклади',
        name: '__slot:translations',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
